<template>
    <single-column-layout>
        <div class="loader-wrapper" v-if="loader">
            <loading position="center"> </loading>
        </div>
        <div class="token container" v-else>

            <div class="token__detail"> 
                <div class="token__detail__header">
                    <h1>
                        {{projectTitle}} 
                        <span v-if="!isStudy">#{{tokenId}}</span>
                    </h1>
                    <span>By {{projectAuthor}}</span>
                    <div class="token__detail__header__social">
                        <img 
                            src="@/assets/icons/logo-twitter.svg" alt="twiter logo"
                        >
                        <a 
                            :href="authorWebsite"
                            target="_blank"
                        >@{{authorWebsite.slice(20)}}   
                        </a> 
                    </div>
                </div>

                <div class="token__detail__image__mobile"> 
                    <div class="token__image__src">
                        <img :src="imageSrc" alt="tokent test image">
                    </div>
                    <div class="token__image__footer">
                        <a 
                            :href="imagePreviewUrl"
                            target="_blank"
                        >image</a>
                        <router-link 
                            :to="{ 
                                name: 'gallery', 
                                params: { projectId: projectId }
                            }"
                        >view entire gallery
                        </router-link>
                    </div>
                    <slot></slot>
                </div>                

                <div class="token__detail__subheader">
                    <span> <b>Date Minted:</b> {{dateMinted}} </span>
                    <span> <b>Mint Price:</b> {{mintPrice}} sSCRT </span>
                    <span v-if="isStudy"> <b>Edition Size: </b> 16 </span>
                    <span> <b>Copyright:</b> {{copyRight}} </span>
                </div>

                <div v-if="stashhLink && project && project.snip721ContractAddress">
                    <stashh-link
                        :collectionId="project.snip721ContractAddress"
                        :tokenId="tokenId"
                    >   
                    </stashh-link>
                </div>
                <div v-else></div>

                <div v-if="showDescription" class="token__detail__description">
                    <description></description>
                </div>
                <div v-else class="token__detail__description">
                    <article>
                        <p>{{ description }}</p>
                        <p>{{ nftDescriptionOne }}</p>
                        <p>{{ nftDescriptionTwo }}</p>
                    </article>
                </div>
            </div>

            <div class="token__image"> 
                <div class="token__image__src">
                    <img :src="imageSrc" alt="tokent test image">
                </div>
                <div class="token__image__footer">
                    <a 
                        :href="imagePreviewUrl"
                        target="_blank"
                    >image</a>
                    <router-link 
                        :to="{ 
                            name: 'gallery', 
                            params: { projectId: projectId }
                        }"
                    >view entire gallery
                    </router-link>
                </div>
                <slot></slot>
            </div>
        </div>
    </single-column-layout>
</template>

<script>
import Block from '@lkmx/flare/src/core/Block.vue';
import DoubleColumnLayout from '../layouts/DoubleColumnLayout.vue'
import Description from './Description.vue';
import Loading from '@/components/Loading.vue'
import StashhLink from '@/components/TokenDetail/StashhLink.vue';

export default {
    name: 'test-mint-details',
    components: { Block, DoubleColumnLayout, Description, Loading, StashhLink },
    props: {
        projectTitle: {
            type: String,
            required: true,
        },
        tokenId: {
            type: String,
            required: true,
        },
        projectId: {
            type: String,
            required: true,
        },
        projectAuthor: {
            type: String,
            required: true,
        },
        authorWebsite: {
            type: String,
            required: false,
        },
        dateMinted: {
            type: String,
            required: false,
        },
        mintPrice: {
            type: String,
            required: true,
        },
        copyRight: {
            type: String,
            required: true,
        },
        imageSrc: {
            type: String,
            required: true,
        },
        imagePreviewUrl: {
            type: String,
            required: true,
        },
        showDescription: {
            type: Boolean,
            required: false,
            default:true
        },
        description: {
            type: String,
            required: false,
        },
        nftDescriptionOne: {
            type: String,
            required: false,
        },
        nftDescriptionTwo: {
            type: String,
            required: false,
        },
        loader: {
            type: Boolean,
            required: false,
        },
        stashhLink: {
            type: Boolean,
            required: false,
        },
        isStudy: {
            type: Boolean,
            required: false,
        }
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.token {
    h1, p { 
        margin: 0px; 
    }
    a {
        text-decoration: none;
        color: var(--color-emerald);
        line-height: 18px;
    }
    &.container {
        padding: 42px 0px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 48px;
    }
    &__detail {
        display: flex;
        flex-direction: column;
        row-gap: 26px;
        padding-right: 16px;
        &__header {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            h1 {
                font-style: italic;
                line-height: 46px;
            }
            span {
                font-weight: 700;
                line-height: 24px;
            }
            &__social {
                display: flex;
                column-gap: 8px;
            }
        }
        &__subheader {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            span {
                line-height: 24px;
            }
            b {
                font-weight: 700;
                margin-right: 8px;
            }
        }
        &__image__mobile {
            display: none;
        }
        &__description {
            p {
                text-align: justify;
                margin-bottom: 16px;
            }
        }
    }
    &__image {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        padding-left: 16px;
        position: sticky;
        top: 100px;
        height: fit-content;
        &__footer {
            display: flex;
            justify-content: space-between;
        }
    }
}
@include respond-to("medium and down") {
    .token {
        &.container {
            padding: 0px;
            grid-column-gap: 16px;
        }
        &__detail {
            padding: 0px
        }
        &__image {
            padding: 0px;
        }
    }    
}
@include respond-to("small and down") {
    .token {
        &.container {
            grid-template-columns: unset;
            grid-row-gap: 16px;
        }
        &__detail__image__mobile {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            &__footer {
                display: flex;
                justify-content: space-between;
            }
        }
        &__image {
            display: none;
        }
    }
}
</style>
