<template>
    <page>
        <column :mode=mode number="2" :number-s=numberS>
            <slot></slot>
        </column>
    </page>
</template>

<script>
export default {
    name: "DoubleColumnLayout",
    props: {
        mode: {
            default: "normal",
            type: String
        },
        numberS: {
            default: "1",
            type: String
        },
    }
}
</script>

<style>

</style>
